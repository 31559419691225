.banner-container
  position: relative
  width: 100%
  overflow: hidden
  margin-bottom: 2rem

.banner-image
  width: 100%
  height: auto
  margin-top: 2rem

.banner-text
  width: 42%
  position: absolute
  top: 20px
  left: 8%
  color: rgb(0, 0, 0)
  z-index: 2
  background-color: rgba(255, 255, 255, 0)

  /* Optional: add background for better text visibility
  padding: 10px
  border-radius: 5px
  font-weight: 500

  h1
    font-size: 2.0rem
    margin: 0

  p
    font-size: 1.8rem
    margin-top: 10px

@media (max-width: 768px)
  .banner-text
    background-color: rgba(255, 255, 255, 0.8)

    /* Optional: add background for better text visibility
    top: -10px

    p
      font-size: 1rem
      top: -10px

  .bg-Contact h2
    font-size: larger
    padding: 0rem 2rem

  .bg-Contact-innerBox
    padding: 0rem 1.5rem

  .Page2-innerBox
    padding: 0.5rem 1.5rem

  .innerPage2-h1
    padding: 0rem 1rem
    font-size: 22px

.Custom-row-card
  --bs-gutter-x:0rem !important

.Card-custom
  margin-bottom: 4%

.Card-Box-Body
  padding: 0rem 1rem  !important

.Card-Box
  margin: 2%
  border: 1px solid #cccccc !important

.Card-Title-Custom
  background-color: #fdf3f2
  padding: 1rem !important

  &:hover
    background-color: #ebb2ac
    padding: 1rem !important

.Card-Body-Custom
  padding: 0rem !important

.Card-Text-Custom
  padding: 0.1rem 1rem !important

  p
    line-height: 1.2 !important

.bg-Contact
  background: -webkit-linear-gradient(left, #fca7b1, #fae5e9)
  padding: 2rem 0rem
  margin-top: 2rem
